<div class="mx-auto mt-2 rounded-lg">
    <kendo-tabstrip tabPosition="top">
        <kendo-tabstrip-tab title="{{ 'Truck Visit' | translate }}" [selected]="true">
            <ng-template kendoTabContent>
                <div class="flex space-x-4 p-2">
                    <div class="w-1/3 rounded-lg bg-muted">
                        <div class="flex h-full">
                            <div class="flex items-center justify-center rounded-l-lg bg-primary bg-opacity-75">
                                <div class="p-3">
                                    <ng-icon class="text-5xl text-white" name="faSolidCircleInfo"></ng-icon>
                                </div>
                            </div>
                            <div class="flex-1 rounded-r-lg bg-primary p-2 text-white">
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'TRUCK VISIT STATUS' | translate }}:</span>
                                    {{ model.truckVisitStatusId | codelist: 'TruckVisitStatus' | async }}
                                </p>

                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Booking PIN' | translate }}:</span>
                                    {{ model.pin | empty }}
                                </p>
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Planned gate' | translate }}:</span>
                                    {{ model.plannedGateId | codelist: 'Gate' | async }}
                                </p>
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Organization' | translate }}:</span>
                                    {{ model.organizationId | codelist: 'Organization' | async }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 rounded-lg bg-muted">
                        <div class="flex h-full">
                            <div class="flex items-center justify-center rounded-l-lg bg-success bg-opacity-75">
                                <div class="p-3">
                                    <ng-icon class="text-5xl text-white" name="faSolidTruck"></ng-icon>
                                </div>
                            </div>
                            <div class="flex-1 rounded-r-lg bg-success text-white">
                                <div class="flex-1 rounded-r-lg bg-success p-2 text-white">
                                    <p class="mb-2 font-semibold">
                                        <span class="font-normal">{{ 'Haulier' | translate }}:</span>
                                        {{ model.haulierId | codelist: 'Organization' | async }}
                                    </p>
                                    <p class="mb-2 font-semibold">
                                        <span class="font-normal">{{ 'Driver' | translate }}:</span>
                                        {{ model.driverId | empty }}
                                    </p>
                                    <p class="mb-2 font-semibold">
                                        <span class="font-normal">{{ 'Truck plate' | translate }}:</span>
                                        {{ model.truckVisitTruckPlate | empty }}
                                    </p>
                                    <p class="mb-2 font-semibold">
                                        <span class="font-normal">{{ 'Trailer plate' | translate }}:</span>
                                        {{ model.truckVisitTrailerPlate | empty }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 rounded-lg bg-muted">
                        <div class="flex h-full">
                            <div class="flex items-center justify-center rounded-l-lg bg-danger bg-opacity-75">
                                <div class="p-3">
                                    <ng-icon class="text-5xl text-white" name="faSolidCalendarDays"></ng-icon>
                                </div>
                            </div>
                            <div class="flex-1 rounded-r-lg bg-danger p-2 text-white">
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Port enter' | translate }}:</span>
                                    {{ model.truckVisitPortEnter | datetime | empty }}
                                </p>
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Entry gate' | translate }}:</span>
                                    {{ model.truckVisitEntryGate | empty }}
                                </p>
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Port exit' | translate }}:</span>
                                    {{ model.truckVisitPortExit | datetime: true | empty }}
                                </p>
                                <p class="mb-2 font-semibold">
                                    <span class="font-normal">{{ 'Exit gate' | translate }}:</span>
                                    {{ model.truckVisitExitGate | empty }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'Events' | translate }}">
            <ng-template kendoTabContent>
                <div class="p-2">
                    <app-grid
                        [data]="model.events"
                        [cols]="eventColumns"
                        [filterable]="false"
                        [autoFitColumns]="false"
                        [applyLocalPagination]="true"
                        [allowGridSave]="false"
                        [showToolbar]="false" />
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'Info' | translate }}">
            <ng-template kendoTabContent>
                <div class="p-2">
                    <div class="flex">
                        <div class="w-1/2 p-2">
                            <span class="mb-2 text-neutral-400">Created By</span>
                            <div class="grid grid-cols-12 gap-4">
                                <div class="col-span-3">
                                    <app-text-input
                                        label="Id"
                                        id="createdBy"
                                        [isEditMode]="false"
                                        [(value)]="model.createdBy" />
                                </div>
                                <div class="col-span-3">
                                    <app-datetime-input
                                        label="Date"
                                        id="createdDate"
                                        [isEditMode]="false"
                                        [(value)]="model.createdDate" />
                                </div>
                            </div>
                        </div>
                        @if (model.LastModifiedBy) {
                            <div class="w-1/2 p-2">
                                <span class="font-semibold text-neutral-400">Modified:</span>
                                <hr />
                                <div class="p-2">
                                    <app-text-input
                                        label="Id"
                                        id="lastModifiedBy"
                                        [isEditMode]="false"
                                        [(value)]="model.LastModifiedBy" />
                                    <app-datetime-input
                                        label="Date"
                                        id="lastModifiedDate"
                                        [isEditMode]="false"
                                        [(value)]="model.LastModifiedDate" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'Revisions' | translate }}">
            <ng-template kendoTabContent>
                <div class="p-2">
                    <app-grid
                        [data]="model.revisions"
                        [cols]="revisionsColumns"
                        [filterable]="false"
                        [autoFitColumns]="false"
                        [applyLocalPagination]="true"
                        [allowGridSave]="false"
                        [showToolbar]="false" />
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>
