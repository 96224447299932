import { customCondition } from '@administration/codelists/codelists.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { BookingPermissions } from '@common/classes/permissions';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { FormProperty } from '@common/components/app-control/app-control.component';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { FleetTypeCodes } from '@common/known-types/fleet-type.codes';
import { CommonService } from '@common/services/common.service';
import { BookingService } from '../../booking.service';
import { BookingListDefinitionsService } from '../booking-list-definitions.service';
import { BookingListUtilsService } from '../booking-list-utils.service';

@Component({
    selector: 'app-booking-container-list',
    templateUrl: './booking-container-list.component.html',
    providers: [CommonService]
})
export class BookingContainerListComponent extends BaseListComponent implements OnInit, OnDestroy {
    queryName = 'ContainerBookings';
    columns: ColumnSettings[] = [
        this.def.bookingColumn,
        this.def.cargoTypeColumn,
        this.def.bookingStatusColumn,
        this.def.pinColumn,
        this.def.blockedColumn,
        {
            field: 'sdDischarge',
            title: this.common.translateService.instant('MC'),
            width: 60,
            type: 'boolean'
        },
        { field: 'containerIn', title: this.common.translateService.instant('Container IN'), width: 60 },
        { field: 'containerOut', title: this.common.translateService.instant('Container OUT'), width: 60 },
        this.def.organizationColumn,
        this.def.timeFromColumn,
        this.def.gateColumn,
        this.def.haulerColumn,
        this.def.driverColumn,
        this.def.truckColumn,
        this.def.trailerColumn
    ];
    get filterProperties(): FormProperty[] {
        return [
            { initialValue: this.filter?.bookingId, ...this.def.bookingFilterProperty },
            { initialValue: this.filter?.status, ...this.def.statusFilterProperty },
            { initialValue: this.filter?.pin, ...this.def.pinFilterProperty },
            {
                name: 'container',
                label: this.common.translateService.instant('Container'),
                colSpan: 4,
                type: 'string',
                initialValue: this.filter?.container
            },
            {
                name: 'serviceRequest',
                label: this.common.translateService.instant('Service Request'),
                colSpan: 4,
                type: 'string',
                initialValue: this.filter?.serviceRequest
            },
            { initialValue: this.filter?.organizationId, ...this.def.organizationFilterProperty },
            { initialValue: this.filter?.plate, ...this.def.plateFilterProperty },
            { initialValue: this.filter?.timeFrom, ...this.def.dateFromFilterProperty },
            { initialValue: this.filter?.timeTo, ...this.def.dateToFilterProperty },
            { initialValue: this.filter?.haulier, ...this.def.haulierFilterProperty },
            { initialValue: this.filter?.driver, ...this.def.driverFilterProperty },
            {
                initialValue: this.filter?.truck,
                isDisabled: (model) => this.isPlateDisabled(model),
                codelistCustomFilter2: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Truck }
                ],
                ...this.def.truckFilterProperty
            },
            {
                initialValue: this.filter?.trailer,
                isDisabled: (model) => this.isPlateDisabled(model),
                codelistCustomFilter2: [
                    { name: 'FleetType', condition: customCondition.Equal, value: FleetTypeCodes.Trailer }
                ],
                ...this.def.trailerFilterProperty
            },
            { initialValue: this.filter?.common, ...this.def.commonFilterProperty },
            {
                name: 'automaticReservation',
                label: this.common.translateService.instant('Automatic Reservation'),
                colSpan: 4,
                type: 'boolean',
                nullable: true,
                initialValue: this.filter?.automaticReservation
            },
            { name: '', type: 'empty', colSpan: 4 },
            { initialValue: this.filter?.blocked, ...this.def.blockedFilterProperty },
            {
                name: 'rejected',
                label: this.common.translateService.instant('Rejected'),
                colSpan: 4,
                type: 'boolean',
                nullable: true,
                initialValue: this.filter?.rejected
            },
            { name: '', type: 'empty', colSpan: 4 },
            { initialValue: this.filter?.moveDischarge, ...this.def.moveDischargeFilterProperty },
            { initialValue: this.filter?.moveLoad, ...this.def.moveLoadFilterProperty }
        ];
    }

    public gridActions: ActionBarGroup[] = [
        {
            label: 'Actions',
            items: [
                {
                    label: 'Merge',
                    icon: 'faSolidCompress',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => true,
                    isDisabled: () => this.selection.length <= 1,
                    onClick: () => this.booking.mergeBookings(this.selection as number[])
                },
                {
                    label: 'Delete',
                    icon: 'faSolidTrash',
                    iconOnly: true,
                    variant: 'danger',
                    isDisabled: () => !this.booking.canDelete(this.selection),
                    isVisible: () => this.actionHasPermission(BookingPermissions.Container.Edit),
                    onClick: () => this.booking.cancelBookings(this.selection.map((item) => item.id))
                },
                {
                    label: 'Change',
                    icon: 'faSolidPenToSquare',
                    iconOnly: true,
                    variant: 'primary',
                    isVisible: () => true,
                    isDisabled: () => this.selection.length !== 1,
                    onClick: () =>
                        this.util.changeBookingDialog(this.dialogService, {
                            title: this.translateService.instant('Change booking data'),
                            cancelAction: () => this.clearFilter(),
                            cancelText: 'Close',
                            cancelIcon: 'faSolidXmark',
                            confirmText: 'Save',
                            confirmIcon: 'faSolidFloppyDisk',
                            model: this.selection
                        })
                }
            ]
        }
    ];

    public override actionBar: ActionBarGroup[] = [
        {
            label: 'Export',
            items: [
                {
                    label: 'Export',
                    icon: 'faSolidFileArrowDown',
                    iconOnly: true,
                    onClick: () => this.booking.exportToExcel(this.appGrid)
                }
            ]
        }
    ];

    filterCommunication$;

    constructor(
        commonService: CommonService,
        protected def: BookingListDefinitionsService,
        protected booking: BookingService,
        private util: BookingListUtilsService
    ) {
        super(commonService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.actionBar = [...this.actionBar]; // this triggers change detection in actionbar so that buttons can update (isHidden, isVisible, etc.)
        this.filterCommunication$ = this.util.subscribeToFilterLogic();
    }

    isPlateDisabled(model): boolean {
        return model?.driver == null || model?.haulier == null;
    }

    override getDefaultFilter() {
        return {
            common: null,
            automaticReservation: null,
            blocked: null,
            rejected: null,
            moveDischarge: null,
            moveLoad: null
        };
    }

    override clearFilter(name?: string) {
        this.util.clearFilter();
        super.clearFilter(name);
    }

    ngOnDestroy(): void {
        if (this.filterCommunication$) {
            this.filterCommunication$.unsubscribe();
        }
    }
}
