import { fakedCodelists } from '@administration/codelists/codelists.interface';
import { codelists } from '@administration/codelists/codelists.service';
import { inject, Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { twJoin } from 'tailwind-merge';
import { CellCheckButtonComponent } from './booking-container-view/check-button.component';
import { CellEnterLeavePortButtonComponent } from './booking-private-view/check-button.component';
import { BookingViewUtilsService } from './booking-view-utils.service';

@Injectable({
    providedIn: 'root'
})
export class BookingViewDefinitionsService {
    utils = inject(BookingViewUtilsService);
    constructor(private common: CommonService) {}
    //#region Column Def

    bookingIdColumn: ColumnSettings = { field: 'id', title: this.common.translateService.instant('Booking') };

    pinColumn: ColumnSettings = { field: 'pin', title: this.common.translateService.instant('PIN') };

    eqReadyColumn: ColumnSettings = {
        field: 'eqReady',
        title: '',
        iconsWithinCell: {
            iconOnly: true,
            icons: [
                {
                    icon: () => 'faSolidCircle',
                    iconClassCon: this.utils.setIconColor
                }
            ]
        }
    };

    bookingTypeColumn: ColumnSettings = {
        field: 'bookingType',
        title: this.common.translateService.instant('Type'),
        type: 'codelist',
        codelistName: codelists.bookingType
    };

    vehicleColumn: ColumnSettings = { field: 'vehicle', title: this.common.translateService.instant('Vehicle') };

    containerCodeColumn: ColumnSettings = {
        field: 'containerCode',
        title: this.common.translateService.instant('Container')
    };

    serviceRequestColumn: ColumnSettings = {
        field: 'serviceRequest',
        title: this.common.translateService.instant('Service request')
    };

    forwarderColumn: ColumnSettings = {
        field: 'forwarderId',
        type: 'codelist',
        codelistName: fakedCodelists.organization,
        title: this.common.translateService.instant('Forwarder')
    };

    emptyFullColumn: ColumnSettings = {
        field: 'emptyFull',
        title: this.common.translateService.instant('E/F'),
        type: 'codelist',
        codelistName: codelists.emptyFull
    };

    scheduledTimeColumn: ColumnSettings = {
        field: 'timeFrom',
        field2: 'timeTo',
        type: 'datetimespan',
        title: this.common.translateService.instant('Scheduled time')
    };
    //#endregion

    //#region Table Definitions
    containerColumns: ColumnSettings[] = [
        this.eqReadyColumn,
        this.containerCodeColumn,
        {
            title: '',
            templateComponent: CellCheckButtonComponent,
            templateInputs: (row) => ({
                id: row.id,
                codelistName: 'ContainerStatus',
                code: row.containerStatusId
            })
        },
        this.emptyFullColumn,
        this.bookingTypeColumn,
        { field: 'loadedOn', title: this.common.translateService.instant('Loaded on') },
        { field: 'location', title: this.common.translateService.instant('Location') },
        { field: 'errorMessage', title: this.common.translateService.instant('Error') }
    ];

    containerEditColumns: ColumnSettings[] = [
        this.bookingTypeColumn,
        this.containerCodeColumn,
        { field: 'order', title: this.common.translateService.instant('Order') },
        { field: 'size', title: this.common.translateService.instant('Size') },
        this.emptyFullColumn,
        this.eqReadyColumn
    ];

    generalCargoColumns: ColumnSettings[] = [
        { field: 'cargoDescription', title: this.common.translateService.instant('Description') },
        this.bookingTypeColumn
    ];

    privateColumns: ColumnSettings[] = [
        {
            field: 'cargoType',
            title: _('Type'),
            iconsWithinCell: {
                iconOnly: true,
                icons: [
                    {
                        tooltip: this.common.translateService.instant('Cargo Type'),
                        icon: this.utils.showIconForPrivateCargoType,
                        iconClass: twJoin('text-lg text-muted')
                    }
                ]
            }
        },
        this.bookingIdColumn,
        this.scheduledTimeColumn,
        {
            field: 'bookingStatus',
            title: this.common.translateService.instant('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus
        },
        {
            title: 'Action',
            templateComponent: CellEnterLeavePortButtonComponent,
            templateInputs: (row) => ({
                id: row.id,
                status: row.bookingStatus
            })
        }
    ];

    privateEditColumns: ColumnSettings[] = [this.bookingTypeColumn, this.bookingIdColumn, this.pinColumn];

    carColumns: ColumnSettings[] = [
        this.vehicleColumn,
        this.serviceRequestColumn,
        this.bookingTypeColumn,
        this.forwarderColumn
    ];

    carEditColumns: ColumnSettings[] = [this.vehicleColumn, this.serviceRequestColumn, this.forwarderColumn];

    eventColumns: ColumnSettings[] = [
        { field: 'badge', title: this.common.translateService.instant('Badge') },
        this.pinColumn,
        {
            field: 'bookingType',
            title: this.common.translateService.instant('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus
        },
        {
            field: 'truckPlate',
            title: this.common.translateService.instant('Truck plate')
        },
        {
            field: 'TrailerPlate',
            title: this.common.translateService.instant('Trailer plate')
        },
        {
            field: 'eventDate',
            type: 'datetime',
            title: this.common.translateService.instant('Event date')
        },
        {
            field: 'gateStatus',
            title: this.common.translateService.instant('Gate status'),
            type: 'codelist',
            codelistName: codelists.gate
        },
        {
            field: 'lane',
            title: this.common.translateService.instant('Lane')
        }
    ];

    revisionsColumns: ColumnSettings[] = [
        { field: 'id', title: this.common.translateService.instant('Revision') },
        {
            field: 'bookingStatusId',
            title: this.common.translateService.instant('Status'),
            type: 'codelist',
            codelistName: codelists.bookingStatus
        },
        {
            field: 'revisionEventId',
            title: this.common.translateService.instant('Type'),
            type: 'codelist',
            codelistName: codelists.revisionEvent
        },
        {
            field: 'haulierId',
            type: 'codelist',
            codelistName: fakedCodelists.organization,
            title: this.common.translateService.instant('Haulier')
        },
        {
            field: 'responsibleHaulier',
            title: this.common.translateService.instant('Responsible haulier')
        },
        {
            field: 'driverId',
            type: 'codelist',
            codelistName: fakedCodelists.driver,
            title: this.common.translateService.instant('Driver')
        },
        {
            field: 'additionalDriverId',
            type: 'codelist',
            codelistName: fakedCodelists.driver,
            title: this.common.translateService.instant('Additional driver	')
        },
        {
            field: 'truckPlate',
            title: this.common.translateService.instant('Truck')
        },
        {
            field: 'trailerPlate',
            title: this.common.translateService.instant('Trailer')
        },
        {
            field: 'cargo',
            title: this.common.translateService.instant('Cargo')
        },
        {
            field: 'mergedFromBooking',
            title: this.common.translateService.instant('Merged from')
        },

        {
            field: 'mergedToBooking',
            title: this.common.translateService.instant('Merged to')
        },
        {
            field: 'releasedContainer',
            title: this.common.translateService.instant('Released CNT')
        },
        this.scheduledTimeColumn,
        {
            field: 'cvsr',
            title: this.common.translateService.instant('CVSR')
        },
        {
            field: 'sdokDate',
            type: 'datetime',
            title: this.common.translateService.instant('SDOK date')
        },
        {
            field: 'createdDate',
            type: 'datetime',
            title: this.common.translateService.instant('Created date')
        }
    ];
    //#endregion
}
