import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { GridCellContext, GridTemplateComponent } from '@common/interfaces/column-settings.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { TranslateService } from '@ngx-translate/core';

export interface EnterLeavePortButtonInputs {
    id: string;
    status: BookingStatusCodes;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        @if (canEnterPort(status)) {
            <app-button
                class="text-white"
                variant="success"
                tooltip="{{ 'In port' | translate }}"
                (clicked)="enterPort(id)">
                {{ 'In port' | translate }}
            </app-button>
        } @else if (canLeavePort(status)) {
            <app-button
                class="text-white"
                variant="danger"
                tooltip="{{ 'Left the port' | translate }}"
                (clicked)="leavePort(id)">
                {{ 'Left the port' | translate }}
            </app-button>
        }
    `
})
export class CellEnterLeavePortButtonComponent implements GridTemplateComponent, EnterLeavePortButtonInputs {
    @Input() status!: BookingStatusCodes;
    @Input() id!: string;
    @Input() context!: GridCellContext;
    toastrNotificationService = Inject(ToastrNotificationService);
    t = Inject(TranslateService);

    canEnterPort(status: BookingStatusCodes) {
        return status == BookingStatusCodes.Booked;
    }

    canLeavePort(status: BookingStatusCodes) {
        return status == BookingStatusCodes.InPortDelayed || status == BookingStatusCodes.InPort;
    }

    enterPort(row) {
        //TODO
    }
    leavePort(row) {
        //TODO
    }
}
