import { Component, Input } from '@angular/core';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { BookingViewDefinitionsService } from '../booking-view-definitions.service';

@Component({
    selector: 'app-truck-visit-bar',
    templateUrl: './truck-visit-bar.component.html'
})
export class TruckVisitBarComponent {
    @Input() model: any;
    eventColumns: ColumnSettings[] = this.def.eventColumns;
    revisionsColumns: ColumnSettings[] = this.def.revisionsColumns;
    constructor(private def: BookingViewDefinitionsService) {}
}
