import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { BookingViewDefinitionsService } from '../booking-view-definitions.service';
import { BookingViewUtilsService } from '../booking-view-utils.service';
//TODO
@Component({
    selector: 'app-booking-general-cargo-view',
    templateUrl: './booking-general-cargo-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class BookingGeneralCargoViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'GeneralCargoBooking';
    icon: IconKey = 'faSolidWarehouse';

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Booking')} ${this.getIdentifier()}`;
    }

    generalCargoColumns: ColumnSettings[] = this.def.generalCargoColumns;

    override canEdit(): boolean {
        return this.util.canEdit(this.model);
    }

    constructor(
        common: CommonService,
        private def: BookingViewDefinitionsService,
        private util: BookingViewUtilsService
    ) {
        super(common);
        this.actionBar.push(util.getActionBar(this, this.model));
    }
}
