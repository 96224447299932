import { inject, Injectable } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { BookingStatusCodes } from '@common/known-types/booking-status.codes';
import { CargoTypeCodes } from '@common/known-types/cargo-type.codes';
import { PropertyFunction } from '@common/models/util.interface';
import { CommonService } from '@common/services/common.service';
import { DialogCommunicationService } from '@common/services/dialog-communication.service';
import { QueryService } from '@common/services/query.service';
import { BookingsBase } from 'app/booking/bookings.interface';
import { twJoin } from 'tailwind-merge';

@Injectable({
    providedIn: 'root'
})
export class BookingViewUtilsService {
    queryService = inject(QueryService);
    communicationService = inject(DialogCommunicationService);
    common = inject(CommonService);
    constructor() {}

    /**
     * Change color of icon
     * @param row - row in table
     * @returns - class for green and red color
     */
    setIconColor: PropertyFunction<string> = (row: any) => {
        if (row.eqReady) {
            return twJoin('text-success');
        } else {
            return twJoin('text-red-500');
        }
    };

    /**
     * Get appropriate icon based on cargoType
     * @param data get row from grid
     * @returns returns IconKey for appropriate icon
     */
    showIconForPrivateCargoType: PropertyFunction<IconKey> = (data: BookingsBase) => {
        switch (data.cargoTypeId) {
            case CargoTypeCodes.CAR:
                return 'faSolidCarSide' as IconKey;
            case CargoTypeCodes.CONTAINER:
                return 'faSolidTruckMoving' as IconKey;
            case CargoTypeCodes.GENERAL_CARGO:
                return 'faSolidWarehouse' as IconKey;
            default:
                return '' as IconKey;
        }
    };

    getActionBar(view: BaseViewComponent, model: BookingsBase = null): ActionBarGroup {
        return {
            label: 'Actions',
            items: [
                {
                    label: 'Leave',
                    icon: 'faSolidArrowRightFromBracket',
                    isVisible: () => view.viewMode,
                    isDisabled: () => true,
                    onClick: () => view.saveChanges()
                },
                {
                    label: 'Delete',
                    icon: 'faSolidTrash',
                    isVisible: () => view.viewMode,
                    isDisabled: () => !this.canDelete(view.model ?? model),
                    onClick: () => view.navigateToEditMode()
                },
                {
                    label: 'Back',
                    icon: 'faSolidArrowLeftLong',
                    isVisible: () => view.viewMode,
                    onClick: () => view.navigateToList()
                }
                //PDF (Finish => only pdf  and back)
            ]
        };
    }

    //Delete when BookingStatusCode (R,B)
    canDelete(model: BookingsBase): boolean {
        if (!model) return false;
        if (model.bookingStatusId == BookingStatusCodes.Booked || model.bookingStatusId == BookingStatusCodes.Reserved)
            return true;
        else return false;
    }

    //Edit when BookingStatusCode (R,B,P,D)
    canEdit(model: BookingsBase) {
        if (
            model.bookingStatusId == BookingStatusCodes.Booked ||
            model.bookingStatusId == BookingStatusCodes.Reserved ||
            model.bookingStatusId == BookingStatusCodes.InPort ||
            model.bookingStatusId == BookingStatusCodes.InPortDelayed
        )
            return true;
        else return false;
    }
}
