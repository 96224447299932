<div class="mx-auto mt-2 rounded-lg">
    <kendo-tabstrip tabPosition="top">
        <kendo-tabstrip-tab title="{{ title | translate }}" [selected]="true">
            <ng-template kendoTabContent>
                <div class="flex">
                    <div class="w-1/4 p-5">
                        <img class="w-full object-contain" alt="Truck Image" [src]="image" />
                    </div>
                    <div class="w-3/4 p-2">
                        <app-grid
                            [data]="model"
                            [cols]="dataColumns"
                            [filterable]="false"
                            [autoFitColumns]="false"
                            [applyLocalPagination]="true"
                            [allowGridSave]="false"
                            [showToolbar]="false" />
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>
