<app-content-header
    title="{{ 'Car Booking List' | marker }}"
    icon="faSolidCarSide"
    [actionBar]="actionBar"></app-content-header>
<app-active-filters [listOfActiveFilters]="listOfActiveFilters" (clearFilter)="clearFilter($event)" />
<app-grid
    selectBy="row"
    [data]="query()"
    [buttons]="gridActions"
    [nrOfActiveFilters]="nrOfActiveFilters"
    [cols]="columns"
    [parentRoute]="parentRoute"
    [defaultViewMode]="defaultViewMode"
    [queryData]="search"
    [autoFitColumns]="false"
    [selectable]="true"
    (toggleFilter)="toggleFilter()"
    (saveGridSettings)="saveGridSettings($event)"
    (deleteGridSettings)="deleteGridSettings()"
    (selectedKeysChange)="onSelectedKeysChange($event)"
    (toggleFilter)="filterDialog(filterProperties)"
    (clearFilter)="clearFilter($event)"></app-grid>
<input type="file" hidden #openFileDialog accept=".csv" (change)="importAndBookCarsFromCSV($event)" />
