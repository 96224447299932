import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { BookingTypeCodes } from '@common/known-types/booking-type.codes';
import { CommonService } from '@common/services/common.service';
import { BookingVehicleEdit } from 'app/booking/bookings.interface';
import { twJoin } from 'tailwind-merge';
import { BookingViewDefinitionsService } from '../booking-view-definitions.service';
import { BookingViewUtilsService } from '../booking-view-utils.service';

@Component({
    selector: 'app-booking-car-view',
    templateUrl: './booking-car-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class BookingCarViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Car.Edit;
    override createPermission = BookingPermissions.Car.Edit;
    entityName = 'CarBooking';
    icon: IconKey = 'faSolidCarSide';
    filter: any = { vin: '', serviceRequest: '' };
    data: any[];
    vehicles: BookingVehicleEdit[];
    vehiclesTino: BookingVehicleEdit[];

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Car Booking')} ${this.getIdentifier()}`;
    }

    carColumns: ColumnSettings[] = this.def.carColumns;
    carEditColumns: ColumnSettings[] = [
        ...this.def.carEditColumns,
        {
            field: 'vehicle',
            title: '',
            iconsWithinCell: {
                iconOnly: true,
                icons: [
                    {
                        icon: () => 'faSolidCircleArrowRight',
                        iconClass: twJoin('text-primary cursor-pointer'),
                        click: (row) => this.addCarToDischarge(row)
                    }
                ]
            }
        }
    ];

    constructor(
        common: CommonService,
        private def: BookingViewDefinitionsService,
        private util: BookingViewUtilsService
    ) {
        super(common);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.actionBar = [...this.actionBar]; // this triggers change detection in actionbar so that buttons can update (isHidden, isVisible, etc.)
        this.actionBar.push({
            label: 'Actions',
            items: [
                {
                    label: 'Leave',
                    icon: 'faSolidArrowRightFromBracket',
                    isVisible: () => this.viewMode,
                    isDisabled: () => true,
                    onClick: () => this.saveChanges() //TODO
                },
                {
                    label: 'Delete',
                    icon: 'faSolidTrash',
                    isVisible: () => this.viewMode,
                    isDisabled: () => !this.util.canDelete(this.model),
                    onClick: () => this.navigateToEditMode() //TODO
                },
                {
                    label: 'Back',
                    icon: 'faSolidArrowLeftLong',
                    isVisible: () => this.viewMode,
                    onClick: () => this.navigateToList()
                }
                //PDF (Finish => only pdf  and back)
            ]
        });
    }

    addCarToDischarge(row) {}

    override canEdit(): boolean {
        return this.util.canEdit(this.model);
    }

    search() {
        this.common.queryService
            .getQuery<any>('Cars', this.filter, {
                injector: this.common.injector
            })
            .result$.subscribe((res) => (this.data = res.data?.results));
    }

    getVehicles(type: BookingTypeCodes): BookingVehicleEdit[] {
        return this.model.vehicles.filter((item) => item.bookingType === type);
    }

    override modelLoaded(): any {
        super.modelLoaded();
        this.vehicles = this.getVehicles(BookingTypeCodes.DROP_OFF);
        this.vehiclesTino = this.getVehicles(BookingTypeCodes.PICK_UP);
    }
}
