import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { BookingViewDefinitionsService } from '../booking-view-definitions.service';
import { BookingViewUtilsService } from '../booking-view-utils.service';

@Component({
    selector: 'app-booking-container-view',
    templateUrl: './booking-container-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class BookingContainerViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'ContainerBooking';
    icon: IconKey = 'faSolidCube';
    def = inject(BookingViewDefinitionsService);
    filter: any = { svrNumber: '', container: '' };

    override getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode
            ? `${this.translateService.instant('New')} ${entityTitle}`
            : `${this.translateService.instant('Container Booking')} ${this.getIdentifier()}`;
    }

    containerColumns: ColumnSettings[] = this.def.containerColumns;
    containerEditColumns: ColumnSettings[] = this.def.containerEditColumns;

    override canEdit(): boolean {
        return this.util.canEdit(this.model);
    }

    constructor(
        common: CommonService,
        private util: BookingViewUtilsService
    ) {
        super(common);
        this.actionBar.push(this.util.getActionBar(this));
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }
}
