<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            @if (viewMode) {
                <app-card>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <!-- <app-codelist-input
                                label="BOOKING STATUS"
                                defaultLabelFormat="LongCode"
                                codelist="BookingStatus"
                                [(value)]="model.bookingStatusId" /> -->
                            <app-control
                                label="Booking Status"
                                type="codelist"
                                codelist="BookingStatus"
                                codelistLabelFormat="LongCode"
                                [(ngModel)]="model.bookingStatusId" />
                        </div>
                        <div class="col-span-3">
                            <app-datetime-input
                                label="Scheduled time"
                                id="timeFrom"
                                [isEditMode]="editMode"
                                [(value)]="model.timeFrom" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Terminal"
                                id="terminal"
                                [isEditMode]="editMode"
                                [(value)]="model.terminal" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Blocked"
                                id="blocked"
                                [isEditMode]="false"
                                [(value)]="model.blocked" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Damage discharge"
                                id="damageDischarge"
                                [isEditMode]="false"
                                [(value)]="model.damagedDischarge" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Damage load"
                                id="damageLoad"
                                [isEditMode]="false"
                                [(value)]="model.damagedLoad" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <app-control
                                label="Responsible haulier"
                                type="codelist"
                                codelist="Organization"
                                [(ngModel)]="model.responsibleHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-control
                                label="Actual haulier"
                                type="codelist"
                                codelist="Organization"
                                [(ngModel)]="model.actualHaulierId" />
                            <!-- <app-codelist-input
                            label="Actual haulier"
                            id="actualHaulier"
                            codelist="Organization"
                            isEditMode="false"
                            [(value)]="model.actualHaulierId" /> -->
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Driver"
                                id="driverId"
                                [isEditMode]="editMode"
                                [(value)]="model.driverId" />
                        </div>
                        <div class="col-span-2 mt-3">
                            <app-text-input
                                label="Announced Mot no"
                                id="announcedMotNo"
                                [isEditMode]="editMode"
                                [(value)]="model.announcedMotNo" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <app-text-input
                                label="Additional driver"
                                id="additionalDriver"
                                [isEditMode]="editMode"
                                [(value)]="model.additionalDriverId" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Truck"
                                id="truckPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckVisitTruckPlate" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Trailer"
                                id="trailerPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckVisitTrailerPlate" />
                        </div>
                    </div>
                </app-card>
                @if (viewMode) {
                    <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
                }
                <app-panel-booking-data
                    [model]="model.vehicles"
                    [image]="'/assets/img/ACARTruck.png'"
                    [dataColumns]="carColumns"></app-panel-booking-data>
            } @else {
                <div class="flex">
                    <app-card class="w-3/5 pr-2">
                        <div class="flex w-full items-center justify-between pb-2">
                            <span class="w-1/4 w-fit text-lg text-gray-500">
                                {{ 'Veh. for discharge:' | translate }}
                            </span>
                            <app-text-input
                                class="w-1/4 w-full"
                                label="Vin"
                                id="vin"
                                [isEditMode]="editMode"
                                [(value)]="filter.vin" />
                            <app-text-input
                                class="w-1/4 w-full"
                                label="Service request"
                                id="serviceRequest"
                                [isEditMode]="editMode"
                                [(value)]="filter.serviceRequest" />
                            <app-button
                                class="w-1/4 w-full"
                                icon="faSolidMagnifyingGlass"
                                variant="primary"
                                tooltip="Search for car"
                                size="sm"
                                (click)="search()" />
                        </div>
                        <app-grid
                            [data]="data"
                            [cols]="carEditColumns"
                            [defaultViewMode]="defaultViewMode"
                            [queryData]="search"
                            [autoFitColumns]="false"
                            [pageable]="false"
                            (saveGridSettings)="saveGridSettings($event)"
                            (deleteGridSettings)="deleteGridSettings()"></app-grid>
                    </app-card>
                    <app-card class="w-2/5">
                        <span class="text-lg text-gray-500">{{ 'Booking details' | translate }}</span>

                        <div class="flex w-full">
                            <div class="w-1/2">
                                <app-datetime-input
                                    label="Date from"
                                    id="lastModifiedDate"
                                    [isEditMode]="false"
                                    [(value)]="model.timeFrom" />
                                <!-- app-disabled="ctrl.bookingHasVehicleOfType('P') || !ctrl.isSlotEditable()" -->
                            </div>
                            <div class="w-1/2">
                                <app-datetime-input
                                    label="Date to"
                                    id="lastModifiedDate"
                                    [isEditMode]="false"
                                    [(value)]="model.timeTo" />
                            </div>
                        </div>
                        <div class="flex w-full">
                            <div class="w-1/2">
                                <img
                                    class="w-full scale-x-[-1] transform object-contain"
                                    alt="Truck Image"
                                    src="/assets/img/ACARTruck.png" />
                                <span>{{ 'Vehicle for discharge' | translate }}</span>
                                <kendo-listview class="m-2 overflow-auto" [data]="vehiclesTino">
                                    <ng-template kendoListViewItemTemplate let-item>
                                        <div class="flex border" style="min-width: fit-content">
                                            <span class="w-3/4 min-w-fit border pr-2">{{ item?.vehicle }}</span>
                                            <span class="w-1/4 min-w-fit border pr-2">{{ item?.serviceRequest }}</span>
                                            <app-button
                                                class="w-1/4 w-full min-w-fit text-danger"
                                                icon="faSolidXmark"
                                                variant="error"
                                                tooltip="Remove car"
                                                size="sm"
                                                (click)="removeTinoVehicle(item)" />
                                        </div>
                                    </ng-template>
                                </kendo-listview>
                            </div>
                            <div class="w-1/2">
                                <img class="w-full object-contain" alt="Truck Image" src="/assets/img/ACARTruck.png" />
                                <span>{{ 'Vehicle for loading' | translate }}</span>
                                <kendo-listview class="m-2 overflow-auto" [data]="vehicles">
                                    <ng-template kendoListViewItemTemplate let-item>
                                        <div class="flex border" style="min-width: fit-content">
                                            <span class="w-3/4 min-w-fit border pr-2">{{ item?.vehicle }}</span>
                                            <span class="w-1/4 min-w-fit border pr-2">{{ item?.serviceRequest }}</span>
                                            <app-button
                                                class="w-1/4 w-full min-w-fit text-danger"
                                                icon="faSolidXmark"
                                                variant="error"
                                                tooltip="Remove car"
                                                size="sm"
                                                (click)="removeVehicle(item)" />
                                        </div>
                                    </ng-template>
                                </kendo-listview>
                            </div>
                        </div>

                        <div class="-mx-2 flex flex-wrap pt-4">
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Haulier"
                                    id="organization"
                                    codelist="Organization"
                                    [isEditMode]="editMode"
                                    [(value)]="model.organizationId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Responsible Haulier"
                                    id="organization"
                                    codelist="Organization"
                                    [isEditMode]="editMode"
                                    [(value)]="model.organizationId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Driver"
                                    #driver
                                    id="driver"
                                    codelist="Driver"
                                    [customFilter1]="model.organizationId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.driver" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Additional Driver"
                                    #driver
                                    id="driver"
                                    codelist="Driver"
                                    [customFilter1]="model.organizationId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.driver" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Truck"
                                    #truck
                                    id="truck"
                                    codelist="Fleet"
                                    [isEditMode]="editMode"
                                    [(value)]="model.truck" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <div class="flex">
                                    <div class="w-3/4">
                                        <app-codelist-input
                                            label="Trailer"
                                            #trailer
                                            id="trailer"
                                            codelist="Fleet"
                                            [isEditMode]="editMode"
                                            [(value)]="model.trailer" />
                                    </div>
                                    <div class="w-1/4 p-2">
                                        <app-boolean-input
                                            label="No Trailer"
                                            [isEditMode]="editMode"
                                            [(value)]="model.active" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-card>
                </div>
            }
        </ng-template>
    </app-loader>
</div>
