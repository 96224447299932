export enum BookingStatusCodes {
    New = 'N',
    Booked = 'B',
    Canceled = 'C',
    Expired = 'E',
    InPort = 'P',
    Finished = 'F',
    InPortDelayed = 'D',
    FinishedDelayed = 'S',
    Reserved = 'R',
    OutOfPort = 'O',
    Merged = 'M',
    FinishedUnregistered = 'U',
    FinishedDelayedUnregistered = 'V',
    FinishedRejected = 'H',
    InVerification = 'I',
    OnTerminalFromInPort = 'T',
    OnTerminalFromInPortDelayed = 'A',
    FinishedRejectedDelayed = 'G',
    Rejected = 'Z'
}
