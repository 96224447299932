<!-- //TODO -->
<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            <app-card>
                <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                    <div class="col-span-3">
                        <app-control label="Valid From" type="datetime" [(ngModel)]="model.validFromDate" />
                    </div>
                    <div class="col-span-3">
                        <app-control label="Valid Until" type="datetime" [(ngModel)]="model.validUntilDate" />
                    </div>
                    <div class="col-span-2 mt-3">
                        <app-control label="Alert" type="boolean" [(ngModel)]="model.alert" />
                    </div>
                </div>
                <br />
                <div class="grid grid-cols-12">
                    <div class="col-span-12" setEditMode [isEditMode]="editMode">
                        <app-control
                            class="h-80 w-full"
                            label="{{ 'Message' | translate }}"
                            type="textarea"
                            [required]="true"
                            [(ngModel)]="model.content" />
                    </div>
                </div>
            </app-card>
        </ng-template>
    </app-loader>
</div>
