import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { BookingPermissions } from '@common/classes/permissions';
import { CommonService } from '@common/services/common.service';
//TODO
@Component({
    selector: 'app-booking-view',
    templateUrl: './booking-view.component.html',
    providers: [CommonService],
    encapsulation: ViewEncapsulation.None
})
export class BookingViewComponent extends BaseViewComponent implements OnInit {
    override editPermission = BookingPermissions.Default.Edit;
    override createPermission = BookingPermissions.Default.Edit;
    entityName = 'Booking';
    icon: IconKey = 'faSolidFile';

    constructor(common: CommonService) {
        super(common);
    }
}
