import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { ViewMode } from '@common/models/view-mode';
import { BookingListComponent } from './components/list/booking-all-list/booking-list.component';
import { BookingCarListComponent } from './components/list/booking-car-list/booking-car-list.component';
import { BookingContainerListComponent } from './components/list/booking-container-list/booking-container-list.component';
import { BookingGeneralCargoListComponent } from './components/list/booking-general-cargo-list/booking-general-cargo-list.component';
import { BookingPrivateListComponent } from './components/list/booking-private-list/booking-private-list.component';
import { BookingTeslaListComponent } from './components/list/booking-tesla-list/booking-tesla-list.component';
import { DialogChangeBookingComponent } from './components/list/dialog-change-booking/dialog-change-booking.component';
import { BookingCarViewComponent } from './components/view/booking-car-view/booking-car-view.component';
import { BookingContainerViewComponent } from './components/view/booking-container-view/booking-container-view.component';
import { BookingGeneralCargoViewComponent } from './components/view/booking-general-cargo-view/booking-general-cargo-view.component';
import { BookingPrivateViewComponent } from './components/view/booking-private-view/booking-private-view.component';
import { BookingViewComponent } from './components/view/booking-view/booking-view.component';
import { PanelBookingDataComponent } from './components/view/panel-booking-data/panel-booking-data.component';
import { TruckVisitBarComponent } from './components/view/truck-visit-bar/truck-visit-bar.component';

export const BOOKING_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: BookingListComponent
    },
    {
        path: 'car/list',
        component: BookingCarListComponent
    },
    {
        path: 'car/edit/:id',
        component: BookingCarViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'car/view/:id',
        component: BookingCarViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'car/tesla/list',
        component: BookingTeslaListComponent
    },
    {
        path: 'cargo/list',
        component: BookingGeneralCargoListComponent
    },
    {
        path: 'cargo/edit/:id',
        component: BookingGeneralCargoViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'cargo/view/:id',
        component: BookingGeneralCargoViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'container/list',
        component: BookingContainerListComponent
    },
    {
        path: 'container/edit/:id',
        component: BookingContainerViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'container/view/:id',
        component: BookingContainerViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'evn/list',
        component: BookingPrivateListComponent
    },
    {
        path: 'evn/edit/:id',
        component: BookingPrivateViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'evn/view/:id',
        component: BookingPrivateViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'edit/:id',
        component: BookingViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: BookingViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'create',
        component: BookingViewComponent,
        data: {
            mode: ViewMode.create
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    declarations: [
        BookingListComponent,
        BookingViewComponent,
        BookingCarListComponent,
        BookingCarViewComponent,
        BookingTeslaListComponent,
        BookingGeneralCargoListComponent,
        BookingGeneralCargoViewComponent,
        BookingContainerListComponent,
        BookingContainerViewComponent,
        BookingPrivateListComponent,
        BookingPrivateViewComponent,
        DialogChangeBookingComponent,
        TruckVisitBarComponent,
        PanelBookingDataComponent
    ],
    imports: [CommonModule, RouterModule.forChild(BOOKING_ROUTES)]
})
export class BookingModule {}
