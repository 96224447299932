<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            <app-card>
                <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="false">
                    <div class="col-span-3">
                        <app-control
                            label="BOOKING STATUS"
                            type="codelist"
                            codelist="BookingStatus"
                            [(ngModel)]="model.bookingStatusId" />
                    </div>
                    <div class="col-span-3">
                        <app-datetime-input label="SCHEDULED TIME" id="timeFrom" [(value)]="model.timeFrom" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="TERMINAL" id="terminal" [(value)]="model.terminal" />
                    </div>
                    <div class="col-span-1">
                        <app-boolean-input label="Blocked" id="blocked" [(value)]="model.blocked" />
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-3">
                        <app-control
                            label="Responsible haulier"
                            type="codelist"
                            codelist="Organization"
                            [isEditMode]="editMode"
                            [(ngModel)]="model.responsibleHaulierId" />
                    </div>
                    <div class="col-span-3">
                        <app-control
                            label="Actual haulier"
                            type="codelist"
                            codelist="Organization"
                            [isEditMode]="editMode"
                            [(ngModel)]="model.actualHaulierId" />
                        <!-- <app-codelist-input
                            label="Actual haulier"
                            id="actualHaulier"
                            codelist="Organization"
                            isEditMode="false"
                            [(value)]="model.actualHaulierId" /> -->
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Driver" id="driverId" [isEditMode]="false" [(value)]="model.driverId" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input
                            label="Additional driver"
                            id="additionalDriver"
                            [isEditMode]="false"
                            [(value)]="model.additionalDriverId" />
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="false">
                    <div class="col-span-3">
                        <app-number-input
                            label="Service Request"
                            id="serviceRequest"
                            format="#"
                            [(value)]="model.serviceRequest" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Work order" id="workOrder" [(value)]="model.workOrder" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Truck" id="truckPlate" [(value)]="model.truckVisitTruckPlate" />
                    </div>
                    <div class="col-span-3">
                        <app-text-input label="Trailer" id="trailerPlate" [(value)]="model.truckVisitTrailerPlate" />
                    </div>
                </div>
            </app-card>
            <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
            <app-panel-booking-data
                [model]="model.cargo"
                [image]="'/assets/img/GCTruck.png'"
                [dataColumns]="generalCargoColumns"></app-panel-booking-data>
        </ng-template>
    </app-loader>
</div>
