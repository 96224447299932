<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            <app-content-header [title]="getTitle()" [icon]="icon" [actionBar]="actionBar" />
            @if (viewMode) {
                <app-card>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <!-- <app-codelist-input
                            label="BOOKING STATUS"
                            codelist="BookingStatus"
                            [(value)]="model.bookingStatusId" /> -->
                            <app-control
                                label="BOOKING STATUS"
                                type="codelist"
                                codelist="BookingStatus"
                                [(ngModel)]="model.bookingStatusId" />
                        </div>
                        <div class="col-span-3">
                            <app-datetime-input
                                label="SCHEDULED TIME"
                                id="timeFrom"
                                [isEditMode]="editMode"
                                [(value)]="model.timeFrom" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="TERMINAL"
                                id="terminal"
                                [isEditMode]="editMode"
                                [(value)]="model.terminal" />
                        </div>
                        <div class="col-span-1">
                            <app-boolean-input
                                label="Blocked"
                                id="blocked"
                                [isEditMode]="false"
                                [(value)]="model.blocked" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <app-control
                                label="Responsible haulier"
                                type="codelist"
                                codelist="Organization"
                                [(ngModel)]="model.responsibleHaulierId" />
                        </div>
                        <div class="col-span-3">
                            <app-control
                                label="Actual haulier"
                                type="codelist"
                                codelist="Organization"
                                [(ngModel)]="model.actualHaulierId" />
                            <!-- <app-codelist-input
                            label="Actual haulier"
                            id="actualHaulier"
                            codelist="Organization"
                            isEditMode="false"
                            [(value)]="model.actualHaulierId" /> -->
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Driver"
                                id="driverId"
                                [isEditMode]="editMode"
                                [(value)]="model.driverId" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Additional driver"
                                id="additionalDriver"
                                [isEditMode]="editMode"
                                [(value)]="model.additionalDriverId" />
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                        <div class="col-span-3">
                            <app-text-input
                                label="Truck"
                                id="truckPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckVisitTruckPlate" />
                        </div>
                        <div class="col-span-3">
                            <app-text-input
                                label="Trailer"
                                id="trailerPlate"
                                [isEditMode]="editMode"
                                [(value)]="model.truckVisitTrailerPlate" />
                        </div>
                    </div>
                </app-card>
                <app-truck-visit-bar [model]="model"></app-truck-visit-bar>
                <app-panel-booking-data
                    [title]="'Linked bookings'"
                    [model]="model.bookings"
                    [image]="'/assets/img/EVNTruck.png'"
                    [dataColumns]="privateColumns"></app-panel-booking-data>
            } @else {
                <div class="flex">
                    <app-card class="w-2/5 pr-2">
                        <span class="text-lg text-gray-500">{{ 'Booking details' | translate }}</span>
                        <div class="flex w-full">
                            <div class="w-1/2">
                                <app-datetime-input
                                    label="Date"
                                    id="lastModifiedDate"
                                    [isRequired]="true"
                                    [isEditMode]="editMode"
                                    [(value)]="model.timeFrom" />
                            </div>
                        </div>
                        <div class="flex w-full">
                            <img class="w-full object-contain" alt="Truck Image" src="/assets/img/EVNTruck.png" />
                        </div>
                        <span>{{ 'Bookings' | translate }}</span>
                        <div class="-mx-2 flex flex-wrap pt-4">
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Responsible Haulier"
                                    id="organization"
                                    codelist="Organization"
                                    defaultLabelFormat="LongCode"
                                    [isDisabled]="true"
                                    [isEditMode]="editMode"
                                    [(value)]="model.responsibleHaulierId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Haulier"
                                    id="organization"
                                    codelist="Organization"
                                    [isEditMode]="editMode"
                                    [(value)]="model.haulierId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Driver"
                                    #driver
                                    id="driver"
                                    codelist="Driver"
                                    [customFilter1]="model.organizationId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.driverId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Additional Driver"
                                    #driver
                                    id="additionalDriver"
                                    codelist="Driver"
                                    [isDisabled]="!model.driverId"
                                    [customFilter1]="model.organizationId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.additionalDriverId" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Truck"
                                    #truck
                                    id="truck"
                                    codelist="Fleet"
                                    [isDisabled]="!model.haulierId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.truckVisitTrailerPlate" />
                            </div>
                            <div class="mb-4 w-1/2 px-2">
                                <app-codelist-input
                                    label="Trailer"
                                    #trailer
                                    id="trailer"
                                    codelist="Fleet"
                                    [isDisabled]="!model.haulierId"
                                    [isEditMode]="editMode"
                                    [(value)]="model.truckVisitTruckPlate" />
                            </div>
                        </div>
                    </app-card>
                    <app-card class="w-3/5">
                        <span class="w-fit text-lg text-gray-500">
                            {{ 'Link to booking:' | translate }}
                        </span>
                        <div class="flex w-full items-center justify-between">
                            <div class="w-1/4 w-full p-2">
                                <app-text-input
                                    label="Bookings"
                                    id="bookings"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.bookings" />
                            </div>
                            <div class="w-1/4 w-full p-2">
                                <app-text-input label="Vin" id="vin" [isEditMode]="editMode" [(value)]="filter.vin" />
                            </div>
                            <div class="w-1/4 w-full p-2">
                                <app-text-input
                                    label="Container"
                                    id="container"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.container" />
                            </div>
                            <div class="w-1/4 w-full p-2">
                                <app-text-input
                                    class="w-1/4 w-full"
                                    label="Pin"
                                    id="pin"
                                    [isEditMode]="editMode"
                                    [(value)]="filter.pin" />
                            </div>
                            <app-button
                                class="w-full min-w-fit p-2"
                                icon="faSolidMagnifyingGlass"
                                variant="primary"
                                tooltip="Search for car"
                                size="sm"
                                (click)="search()" />
                        </div>
                        <app-grid
                            [data]="data"
                            [cols]="privateEditColumns"
                            [defaultViewMode]="defaultViewMode"
                            [queryData]="search"
                            [autoFitColumns]="false"
                            (saveGridSettings)="saveGridSettings($event)"
                            (deleteGridSettings)="deleteGridSettings()"></app-grid>
                    </app-card>
                </div>
            }
        </ng-template>
    </app-loader>
</div>
